import gsap from 'gsap';
import Viewport from '../core/Viewport';
import { clearTimelineProps } from '../lib/helpers';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const keyhole = el.querySelector('[data-keyhole]');
    const text = el.querySelector('[data-text]');
    const ctaArrow = el.querySelector('[data-cta] [data-arrow]');

    let tl;
    let keyholeObserver;
    let keyholeIsIntersecting;

    const animate = true;

    const killTl = () => {
        if (!tl) {
            return;
        }
        clearTimelineProps(tl);
        tl.kill();
        tl = null;
    };

    const createTl = () => {
        if (tl || !animate) {
            return;
        }
        tl = gsap.timeline({ paused: true })
            .to(text, {
                rotation: -30,
                duration: 1,
                transformOrigin: 'left center',
                ease: 'Power1.easeIn'
            }, 0)
            .to(keyhole, {
                rotation: -30,
                scale: 5,
                xPercent: -100,
                yPercent: 100,
                duration: 1,
                transformOrigin: 'left center',
                ease: 'Power1.easeIn'
            }, 0);
        if (ctaArrow) {
            tl
                .to(ctaArrow, {
                    opacity: 0,
                    duration: 0.3,
                    ease: 'none'
                }, 0.5);
        }
    };

    let raf;

    const onScroll = () => {
        if (!tl) {
            return;
        }
        const {
            top,
            height
        } = el.getBoundingClientRect();
        let progress = Math.abs((top * -1) / height);
        if (progress < 0) {
            progress = 0;
        } else if (progress > 1) {
            progress = 1;
        }
        if (raf) {
            cancelAnimationFrame(raf);
        }
        raf = requestAnimationFrame(() => {
            raf = null;
            if (progress !== tl.progress()) {
                tl.progress(progress);
            }
        });
    };

    const onResize = () => {
        if (keyholeIsIntersecting) {
            createTl();
        } else if (keyhole.offsetParent === null) {
            killTl();
        }
    };

    const init = () => {
        keyholeObserver = new IntersectionObserver(([{ isIntersecting }]) => {
            keyholeIsIntersecting = isIntersecting;
            onResize();
            onScroll();
        });
        keyholeObserver.observe(keyhole);
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        keyholeObserver.disconnect();
        keyholeObserver = null;
        killTl();
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
