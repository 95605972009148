import BookingWidgetHorizontal from '../lib/BookingWidgetHorizontal';
import BookingWidgetVertical from '../lib/BookingWidgetVertical';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const outer = el.firstElementChild;
    const form = el.querySelector('form');
    const toggleBtn = el.querySelector('button[aria-expanded]');

    const getIsAnchoredRight = () => window.getComputedStyle(outer).position === 'sticky';

    let isAnchoredRight = getIsAnchoredRight();
    let widget = null;

    const destroyWidget = () => {
        if (!widget) {
            return;
        }
        widget.destroy();
        widget = null;
    };

    const createWidget = () => {
        destroyWidget();
        if (isAnchoredRight) {
            widget = BookingWidgetHorizontal(el);
        } else {
            widget = BookingWidgetVertical(el);
        }
        widget.init();
    };

    const onFormSubmit = e => {
        form.querySelector('button[type="submit"]')
            .classList
            .add('loading');
    };

    const onFormFocus = () => {
        widget.reveal();
    };

    const onDocClick = e => {
        if (!widget.getIsCollapsible()) {
            return;
        }
        const { target, currentTarget } = e;
        const flatpicker = document.querySelector('.flatpickr-calendar');
        if (target !== document.body && target !== el && !el.contains(target) && (!flatpicker || !flatpicker.contains(target)) && !target.hasAttribute('data-flatpickr-shader')) {
            console.log({ target, currentTarget });
            widget.hide();
        }
    };

    const onKeyUp = e => {
        if (!widget.getIsCollapsible()) {
            return;
        }
        const key = e.code || e.keyCode || e.which || null;
        if (key === 'Escape' || key === 27) {
            widget.hide();
            toggleBtn.focus();
        }
    };

    const onToggleBtnClick = () => {
        if (widget.getIsCollapsed()) {
            widget.reveal();
        } else {
            widget.hide();
        }
    };

    const onResize = () => {
        const wasAnchoredRight = isAnchoredRight;
        isAnchoredRight = getIsAnchoredRight();
        if (wasAnchoredRight !== isAnchoredRight) {
            createWidget();
        }
    };

    const init = () => {
        el.hidden = false;
        createWidget();
        toggleBtn.addEventListener('click', onToggleBtnClick);
        document.addEventListener('click', onDocClick);
        document.addEventListener('focusin', onDocClick);
        document.addEventListener('keyup', onKeyUp);
        form.addEventListener('submit', onFormSubmit);
        form.addEventListener('focusin', onFormFocus);
        Viewport.on('resize', onResize);
        console.log('created large widget');
    };

    const destroy = () => {
        destroyWidget();
        form.removeEventListener('submit', onFormSubmit);
        form.removeEventListener('focusin', onFormFocus);
        toggleBtn.removeEventListener('click', onToggleBtnClick);
        document.removeEventListener('click', onDocClick);
        document.removeEventListener('focusin', onDocClick);
        document.removeEventListener('keyup', onKeyUp);
        Viewport.off('resize', onResize);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
