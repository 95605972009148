import ChoicesSelect from '../lib/ChoicesSelect';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const dom = $(el);
    const select = dom.find('select').get(0);

    let choices;

    const init = () => {
        choices = ChoicesSelect(select, {
            searchEnabled: true,
            shouldSort: false,
            allowHTML: true,
            searchResultLimit: 8,
            searchPlaceholderValue: 'Type to search'
        });
    };

    const destroy = () => {
        if (choices) {
            choices.destroy();
            choices = null;
        }
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };
};
