import { loadChoices } from './async-bundles';
import $ from '../core/Dom';

async function ChoicesSelect(select, config) {

    const selectHtml = $(select).html();
    let choices;

    async function create() {
        loadChoices(({ default: Choices }) => {
            choices = new Choices(select, config);
        });
    };

    const destroy = () => {
        if (!choices) {
            return;
        }
        choices.removeActiveItems();
        choices.destroy();
        choices = null;
        $(select).html(selectHtml);
    };

    async function reset() {
        if (!choices) {
            return;
        }
        destroy();
        await create();
    };

    await create();

    const self = { destroy, reset, choices };

    $(select).data('choicesSelect', self);

    return self;

}

export default ChoicesSelect;
