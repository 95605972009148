import gsap from 'gsap';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import { isLivePreview, reduceMotion, animatedScroll } from './lib/helpers';
import $ from './core/Dom';
import Revealer from './lib/Revealer';
import Transition from './lib/Transition';
import Dispatch from './core/Dispatch';
import { COMPONENT_INIT } from './lib/events';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();

    $('body').on('click', '[data-scroll-target]', e => {
        const href = e.triggerTarget.getAttribute('href');
        if (!href || href[0] !== '#') {
            return true;
        }
        const target = $(href).get(0);
        if (!target) {
            return true;
        }
        e.preventDefault();
        animatedScroll(target);
        return false;
    });

    window.addEventListener('popstate', () => {
        window.location.reload();
    });

    let timer;
    window.addEventListener('scroll', () => {
        clearTimeout(timer);
        document.body.classList.add('pointer-events-none');
        timer = setTimeout(() => {
            document.body.classList.remove('pointer-events-none');
        }, 100);
    }, false);

    if (!reduceMotion()) {
        document.documentElement.classList.remove('reduce-motion');
        if (!isLivePreview()) {
            Revealer.init();
            Transition.init();

            if (ENV !== 'production') {
                setTimeout(() => {
                    Dispatch.on(COMPONENT_INIT, () => {
                        setTimeout(Revealer.update, 0);
                    });
                }, 0);
            }
        }

    }

};

require('doc-ready')(init);
