import gsap from 'gsap';
import serialize from 'form-serialize';
import $ from '../core/Dom';
import superagent from '../core/request';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default form => {

    const $form = $(form);
    const errorMessage = $form.find('[data-error]').get(0);
    const receipt = $form.find('[data-receipt]').get(0);
    const hideReceiptBtn = $form.find('[data-receipt] [role="button"]').get(0);
    const submitBtn = $form.find('button[type="submit"]').get(0);

    let isSubmitting = false;

    const disableSubmit = () => {
        submitBtn.setAttribute('aria-disabled', 'true');
    };

    const enableSubmit = () => {
        submitBtn.removeAttribute('aria-disabled');
    };

    const reset = e => {
        if (e) {
            e.preventDefault();
        }
        // Clear input fields
        $form.find('input:not([type="hidden"]),textarea').get().forEach(input => {
            if (['radio', 'checkbox'].indexOf(input.getAttribute('type')) > -1) {
                input.checked = false;
            } else {
                input.value = null;
            }
        });
        $form.find('select').each(select => {
            const { choicesSelect } = $(select).data();
            if (choicesSelect) {
                choicesSelect.reset();
            }
        });
        // Hide receipt and show submit button
        gsap.timeline({
            onComplete() {
                receipt.hidden = true;
            }
        })
            .to(receipt, { opacity: 0, scale: 1.1, duration: 0.3 }, 0);
        setTimeout(() => {
            enableSubmit();
            Viewport.releaseTabbing(submitBtn);
        }, 0);
    };

    const onHideReceiptBtnKeyUp = e => {
        e.preventDefault();
        if (e.key === 'Enter' || e.key === ' ') {
            reset();
        }
    };

    const onHideReceiptBtnClick = e => {
        e.preventDefault();
        reset();
    };

    const showReceipt = () => {
        receipt.hidden = false;
        gsap.timeline()
            .fromTo(receipt, { opacity: 0, scale: 1.1 }, { opacity: 1, scale: 1, duration: 0.3 }, 0.15);
        try {
            hideReceiptBtn.focus();
        } catch (error) {
            // Don't care
        }
        Viewport.lockTabbing(receipt);
    };

    const showGenericError = () => {
        errorMessage.hidden = false;
        errorMessage.focus = true;
    };

    const clearErrors = () => {
        if (errorMessage) {
            errorMessage.hidden = true;
        }
        $form.find('[data-errors]').each(node => {
            node.textContent = '';
            node.hidden = true;
        });
        $form.find('[data-input]').removeClass('has-errors');
    };

    const getErrorNames = (errors, prefix = '') => Object.keys(errors || {}).reduce((carry, key) => {
        if (typeof errors[key] === 'object' && !Array.isArray(errors[key])) {
            return {
                ...carry,
                ...getErrorNames(errors[key], key)
            };
        }
        const name = prefix ? `${prefix}[${key}]` : key;
        return {
            ...carry,
            [name]: errors[key]
        };
    }, {});

    const showErrors = (errors = []) => {

        clearErrors();

        const errorsByName = getErrorNames(errors);
        const errorNames = Object.keys(errorsByName);

        if (!errorNames.length) {
            showGenericError();
            return;
        }

        errorNames.forEach(name => {
            const input = $form.find(`[data-input="${name}"]`).get(0);
            if (input) {
                input.classList.add('has-errors');
            }
            const errorsDiv = $form.find(`[data-errors="${name}"]`).get(0);
            if (errorsDiv) {
                errorsDiv.hidden = false;
                $(errorsDiv).html([].concat(errorsByName[name]).join('<br/>'));
            }
        });

        const firstErrorInput = $form.find('[data-input].has-errors').find('input:not([type="hidden"]),textarea,select').get(0);
        if (firstErrorInput) {
            try {
                firstErrorInput.focus();
            } catch (error) {
                // Don't care.
            }
        }

        enableSubmit();

    };

    const onSubmit = e => {

        e.preventDefault();

        if (isSubmitting || submitBtn.hasAttribute('aria-disabled')) {
            return;
        }

        isSubmitting = true;
        disableSubmit();

        submitBtn.classList.add('loading');

        const data = serialize($form.get(0));
        const url = window.location.href.split('?')[0].split('#')[0];

        superagent
            .post(url)
            .accept('application/json')
            .send(data)
            .then(({ status }) => {
                clearErrors();
                if (status !== 200) {
                    throw new Error();
                }
                showReceipt();
            })
            .catch(error => {
                const { response = {} } = error || {};
                const { errors } = response.body || {};
                showErrors(errors);
            })
            .then(() => {
                isSubmitting = false;
                submitBtn.classList.remove('loading');
                enableSubmit();
            });
    };

    const init = () => {
        $form.on('submit', onSubmit);
        $form.find('a[href]:not([href^="#"])').each(link => {
            $(link).attr({
                target: '_blank',
                rel: 'noopener noreferrer'
            });
        });
        hideReceiptBtn.removeAttribute('href');
        hideReceiptBtn.addEventListener('click', onHideReceiptBtnClick);
        hideReceiptBtn.addEventListener('keyup', onHideReceiptBtnKeyUp);
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
        hideReceiptBtn.removeEventListener('click', onHideReceiptBtnClick);
        hideReceiptBtn.removeEventListener('keyup', onHideReceiptBtnKeyUp);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };
};
