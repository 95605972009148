import gsap from 'gsap';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const star = el.firstElementChild;

    let isTweening = false;
    let isVisible = false;
    let observer = null;

    let scrollTimer = null;

    const animate = () => {
        if (gsap.isTweening(star)) {
            return;
        }
        gsap.timeline()
            .to(star, { rotate: 360, ease: 'Quint.easeInOut', duration: 1 })
            .set(star, { clearProps: 'transform' });
    };

    const clearTimer = () => {
        if (!scrollTimer) {
            return;
        }
        clearTimeout(scrollTimer);
        scrollTimer = null;
    };

    const onScroll = () => {
        if (!isVisible) {
            return;
        }
        clearTimer();
        scrollTimer = setTimeout(() => {
            clearTimer();
            animate();
        }, 10);
    };

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            isVisible = isIntersecting;
            if (!isVisible) {
                clearTimer();
            }
        });
        observer.observe(el);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        observer.disconnect();
        observer = null;
        clearTimer();
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
