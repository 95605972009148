import gsap from 'gsap';
import { clearTimelineProps } from './helpers';
import Viewport from '../core/Viewport';

export default el => {

    const outer = el.firstElementChild;
    const inner = outer.firstElementChild;
    const ball = inner.firstElementChild;
    const toggleBtn = el.querySelector('button[aria-expanded]');
    const svgCircle = el.querySelector('[data-circle]');
    const svgCircleShadow = svgCircle.querySelector('feDropShadow');

    const INITIAL_SHADOW_OPACITY = parseFloat(svgCircleShadow.getAttribute('flood-opacity'));
    const shadowSetter = gsap.quickSetter(svgCircleShadow, 'flood-opacity');

    let isCollapsed = true;
    let prevScrollTop = Viewport.scrollTop;
    let tl;

    const getIsCollapsible = () => !isCollapsed;

    const getIsCollapsed = () => isCollapsed;

    const killTimeline = () => {
        if (!tl) {
            return;
        }
        tl.kill();
        clearTimelineProps(tl);
        tl = null;
    };

    const createTimeline = () => {
        tl = gsap.timeline({ paused: true })
            .to(outer, {
                y: '-100%',
                duration: 0.5,
                ease: 'Back.easeInOut'
            }, 0)
            .to(ball, {
                y: '11%',
                duration: 0.5,
                ease: 'Back.easeInOut'
            }, 0);
    };

    const reveal = () => {
        if (!isCollapsed) {
            return;
        }
        el.classList.remove('is-collapsed');
        toggleBtn.setAttribute('aria-expanded', 'true');
        isCollapsed = false;
        gsap.to(tl, { progress: 1, duration: 0.65, ease: 'none' });
    };

    const hide = () => {
        if (isCollapsed) {
            return;
        }
        el.classList.add('is-collapsed');
        toggleBtn.setAttribute('aria-expanded', 'false');
        isCollapsed = true;
        gsap.to(tl, { progress: 0, duration: 0.5, ease: 'none' });
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - prevScrollTop) < 20) {
            return;
        }
        prevScrollTop = scrollTop;
        hide();
    };

    const init = () => {
        shadowSetter(INITIAL_SHADOW_OPACITY);
        createTimeline();
        Viewport.on('scroll', onScroll);
        console.log('created small widget');
    };

    const destroy = () => {
        killTimeline();
        Viewport.on('scroll', onScroll);
    };

    return {
        init,
        destroy,
        reveal,
        hide,
        getIsCollapsible,
        getIsCollapsed
    };

};
