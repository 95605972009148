import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const $body = $('body');
    const $el = $(el);
    const button = $el.find('button[aria-expanded]').get(0);
    const $buttonLabel = $(button).find('[data-label]').eq(0);
    const menu = button.nextElementSibling;

    let isExpanded = false;
    let tl = null;
    let observer;

    const expand = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        button.setAttribute('aria-expanded', 'true');
        menu.hidden = false;
        button.focus();
        if (tl) {
            tl.kill();
            gsap.killTweensOf(menu);
        }
        tl = gsap.timeline({
            onComplete() {
                tl = null;
            }
        })
            .to(button, {
                borderRadius: 8,
                duration: 0.3
            }, 0)
            .fromTo(menu, {
                width: '100%',
                height: '100%'
            }, {
                width: 'auto',
                height: 'auto',
                duration: 0.3,
                ease: 'Back.easeOut'
            }, 0);
    };

    const collapse = (tween = true) => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        button.setAttribute('aria-expanded', 'false');
        const focusedElement = document.activeElement || null;
        if (menu.contains(focusedElement)) {
            button.focus();
        }
        if (tl) {
            tl.kill();
            gsap.killTweensOf(menu);
        }
        if (!tween) {
            menu.hidden = true;
            tl = null;
            return;
        }
        tl = gsap.timeline({
            onComplete() {
                menu.hidden = true;
                tl = null;
            }
        })
            .to(button, {
                borderRadius: 16,
                duration: 0.3
            }, 0)
            .to(menu, {
                width: '100%',
                height: '100%',
                duration: 0.3,
                ease: 'Back.easeIn'
            }, 0);
    };

    const toggle = () => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    $(button)
        .on('click', toggle);

    const onBodyClick = e => {
        if (!isExpanded || e.target === button || button.contains(e.target) || e.target === menu || menu.contains(e.target)) {
            return;
        }
        collapse();
    };

    const onBodyKeyUp = e => {
        if (!isExpanded || e.key !== 'Escape') {
            return;
        }
        collapse();
    };

    const onBreakpoint = () => {
        if (!isExpanded) {
            return;
        }
        if (!button.offsetParent) {
            collapse(false);
        }
    };

    const onItemClick = e => {
        const { triggerTarget: item } = e;
        $buttonLabel.html(item.dataset.activetext || $(item).html());
        menu.querySelector('[aria-current]').removeAttribute('aria-current');
        item.setAttribute('aria-current', 'true');
        collapse(false);
    };

    const init = () => {
        $body.on('click focusin', onBodyClick);
        $body.on('keyup', onBodyKeyUp);
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isExpanded && !isIntersecting) {
                collapse();
            }
        });
        $el.on('click', 'a', onItemClick);
        observer.observe(button);
        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        $(button)
            .off('click');
        $body.off('click focusin', onBodyClick);
        $body.off('keyup', onBodyKeyUp);
        $el.off('click');
        observer.disconnect();
        observer = null;
        Viewport.off('breakpoint', onBreakpoint);
        collapse(false);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
