import gsap from 'gsap';
import serialize from 'form-serialize';
import { loadFlickity } from '../lib/async-bundles';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Components from '../core/Components';
import superagent from '../core/request';
import { animatedScroll } from '../lib/helpers';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, PROGRAMMATIC_SCROLL_END, PROGRAMMATIC_SCROLL_START } from '../lib/events';

export default el => {

    const $el = $(el);

    const viewToggle = $el.find('[data-viewtoggle]').get(0);
    const sortMenu = $el.find('[data-sortmenu]').get(0);
    const mapContainer = $el.find('[data-map]').get(0);
    const mapListings = $el.find('[data-map-listings]').get(0);
    const searchForm = $el.find('form[role="search"]').get(0);
    const announcer = $el.find('#stays-listing-announcer').get(0);

    const mapMarkers = [];
    const markersByUid = {};

    let { view: activeView } = el.dataset;

    let { width: viewportWidth, height: viewportHeight } = Viewport;
    let mapObserver;
    let map;
    let activeMarker;
    let request;
    let complexObserver;
    let suppressComplexObserver = false;
    let observedComplexes = [];

    let listingSlider = null;

    let Flickity;

    const isSmall = () => viewToggle.offsetParent !== null;

    const getMapProps = () => JSON.parse(mapContainer.getAttribute('data-map')) || {};

    const killListingSlider = () => {

        if (!listingSlider) {
            return;
        }

        const { element: slider } = listingSlider;

        listingSlider.destroy();
        listingSlider = null;

        $(slider)
            .off('focusin');

        $(slider).find('button[data-next],button[data-prev]').off('click');

        slider.parentNode.hidden = true;

        console.log('listing slider killed');
    };

    const createListingSlider = marker => {

        if (!Flickity) {
            return;
        }

        console.log('create listings slider...');

        if (!mapListings || mapListings.offsetParent === null || !marker || marker.element.classList.contains('hidden')) {
            console.log('NOPE');
            killListingSlider();
            return;
        }

        const { complexUid } = marker.element.dataset;
        const slider = $el.find(`[data-listings-slider="${complexUid}"]`).get(0);

        console.log('slider', { slider }, slider.hidden);

        if (!slider || !slider.hidden) {
            return;
        }

        killListingSlider();

        slider.hidden = false;

        const listings = slider.querySelector('[data-listings]');
        const nextBtn = slider.querySelector('button[data-next]');
        const prevBtn = slider.querySelector('button[data-prev]');

        listingSlider = new Flickity(listings, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            groupCells: false,
            prevNextButtons: false,
            pageDots: false,
            freeScroll: false,
            freeScrollFriction: 0.045,
            resize: false,
            adaptiveHeight: false,
            setGallerySize: true,
            wrapAround: false,
            accessibility: false,
            on: {
                dragStart() {
                    document.ontouchmove = e => e.preventDefault();
                },
                dragEnd() {
                    document.ontouchmove = () => true;
                },
                select() {
                    if (this.selectedIndex <= 0) {
                        prevBtn.setAttribute('aria-disabled', 'true');
                        prevBtn.setAttribute('tabindex', '-1');
                    } else {
                        prevBtn.removeAttribute('aria-disabled');
                        prevBtn.removeAttribute('tabindex');
                    }
                    if (this.selectedIndex >= this.slides.length - 1) {
                        nextBtn.setAttribute('aria-disabled', 'true');
                        nextBtn.setAttribute('tabindex', '-1');
                    } else {
                        nextBtn.removeAttribute('aria-disabled');
                        nextBtn.removeAttribute('tabindex');
                    }
                    this.cells.forEach(({ element }) => {
                        element.removeAttribute('aria-hidden');
                    });
                },
                ready() {
                    this.resize();
                }
            }
        });

        $(slider)
            .on('focusin', 'a', e => {
                slider.parentNode.scrollLeft = 0;
                const { triggerTarget: link } = e;
                const cell = listingSlider.cells.find(({ element }) => element.contains(link));
                if (!cell) {
                    return;
                }
                const index = listingSlider.cells.indexOf(cell);
                if (index > -1) {
                    listingSlider.selectCell(index);
                }
            });

        $(nextBtn).on('click', () => {
            listingSlider.next();
        });

        $(prevBtn).on('click', () => {
            listingSlider.previous();
        });

        console.log('listing slider created');

    };

    const setActiveComplex = complex => {
        if (complex.offsetParent === null) {
            return;
        }
        const $complex = $(complex);
        const $complexToggle = $complex.find('button[aria-expanded]');
        const { _accordion: accordion } = $complexToggle ? $complexToggle.data() : null;
        if (accordion) {
            accordion.expand();
        }
        animatedScroll($complex.get(0));
    };

    const setActiveMarker = (marker = null) => {
        if (!map || mapContainer.offsetParent === null || activeMarker === marker) {
            return;
        }
        if (map.getZoom() > 15) {
            map.setZoom(15);
        }
        if (activeMarker) {
            activeMarker.element.classList.remove('is-active');
            activeMarker.element.setAttribute('aria-pressed', 'false');
        }
        if (marker) {
            activeMarker = marker;
            activeMarker.element.classList.add('is-active');
            activeMarker.element.setAttribute('aria-pressed', 'true');
            map.panTo(activeMarker.position);
            createListingSlider(activeMarker);
        } else {
            killListingSlider();
            activeMarker = null;
            const { center } = getMapProps();
            map.panTo(center);
        }
    };

    let firstLoad = true;

    const fitMapToMarkers = () => {
        if (!map) {
            return;
        }
        const visibleMarkers = mapMarkers.filter(marker => !marker.element.classList.contains('hidden'));
        if (activeMarker && visibleMarkers.indexOf(activeMarker) > -1) {
            if (map.getZoom() > 15) {
                map.setZoom(15);
            }
            if (firstLoad) {
                map.setCenter(activeMarker.position);
            } else {
                map.panTo(activeMarker.position);
            }
        } else if (visibleMarkers.length) {
            // eslint-disable-next-line no-undef
            const bounds = new google.maps.LatLngBounds();
            visibleMarkers.forEach(marker => {
                bounds.extend(marker.position);
            });
            // eslint-disable-next-line no-undef
            google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
                // Prevent the map from zooming too close when bounds change
                if (map.getZoom() > 15) {
                    map.setZoom(15);
                }
            });
            map.fitBounds(bounds);
        } else {
            if (map.getZoom() > 15) {
                map.setZoom(15);
            }
            const { center } = getMapProps();
            if (firstLoad) {
                map.setCenter(center);
            } else {
                map.panTo(center);
            }
        }
        firstLoad = false;
    };

    async function addMarkersToMap(markersToAdd) {

        if (!map) {
            return;
        }

        // eslint-disable-next-line no-undef,no-new
        const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

        const { markerHtml } = getMapProps();

        const visibleMarkers = [];

        let newActiveMarker = null;

        markersToAdd.forEach(({
            title,
            uid,
            position,
            active
        }) => {
            let marker = markersByUid[uid] || null;
            if (!marker) {
                // This marker doesn't exist, so create it
                // eslint-disable-next-line no-undef,no-new
                const html = $(markerHtml.replace('{title}', title)).get(0);
                marker = new AdvancedMarkerElement({ map, position, content: html });
                marker.element.classList.add('group');
                marker.element.setAttribute('data-complex-uid', uid);
                marker.element.setAttribute('aria-pressed', 'false');
                marker.element.setAttribute('tabIndex', '0');
                markersByUid[uid] = marker;
                marker.addListener('click', () => {
                    setActiveMarker(marker);
                    const complex = $el.find(`[data-complex="${uid}"]`).get(0);
                    if (complex) {
                        setActiveComplex(complex);
                    }
                    if (isSmall()) {
                        animatedScroll(mapContainer, { ease: 'Power2.easeOut', duration: 0.5 });
                    }
                });
                mapMarkers.push(marker);
            }
            if (active && !newActiveMarker) {
                newActiveMarker = marker;
            }
            visibleMarkers.push(marker);
        });

        // Hide markers?
        Object.values(markersByUid).forEach(marker => {
            if (visibleMarkers.indexOf(marker) === -1) {
                marker.element.classList.add('hidden');
            } else {
                marker.element.classList.remove('hidden');
            }
        });

        if (newActiveMarker) {
            setActiveMarker(newActiveMarker);
        } else if (visibleMarkers.length === 1) {
            setActiveMarker(visibleMarkers[0]);
        } else if (observedComplexes.length) {
            const { complex: uid } = observedComplexes[observedComplexes.length - 1].dataset;
            const marker = markersByUid[uid] || null;
            if (marker && visibleMarkers.indexOf(marker) > -1) {
                setActiveMarker(marker);
            }
        }

        fitMapToMarkers();

    }

    async function initMap() {
        if (map) {
            return;
        }
        const {
            mapId,
            markers,
            center
        } = getMapProps();
        // eslint-disable-next-line no-undef
        const { Map } = await google.maps.importLibrary('maps');
        map = new Map(mapContainer, {
            zoom: 15,
            maxZoom: 17,
            disableDefaultUI: true,
            mapId,
            center
        });
        addMarkersToMap(markers || []);
        // Map is loaded, fade it in
        // eslint-disable-next-line no-undef
        google.maps.event.addListenerOnce(map, 'idle', () => {
            gsap.fromTo(mapContainer, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3
            });
        });
    }

    const toggleView = () => {
        if (activeView === 'list') {
            activeView = 'map';
        } else {
            activeView = 'list';
        }
        el.setAttribute('data-view', activeView);
        fitMapToMarkers();
        if (listingSlider) {
            listingSlider.resize();
        } else if (activeMarker) {
            createListingSlider(activeMarker);
        }
    };

    const onViewToggleClick = () => {
        toggleView();
    };

    const onComplexesObserve = entries => {
        for (let i = 0; i < entries.length; i += 1) {
            const {
                target,
                isIntersecting
            } = entries[i];
            if (isIntersecting) {
                observedComplexes.push(target);
            } else {
                const index = observedComplexes.indexOf(target);
                if (index > -1) {
                    observedComplexes.splice(index, 1);
                }
            }
        }
        if (suppressComplexObserver || !observedComplexes.length) {
            return;
        }
        const observedComplex = observedComplexes.toReversed().reduce((carry, complex) => {
            if (carry || complex.querySelector('[aria-expanded="false"]')) {
                return carry;
            }
            return complex;
        }, null);
        if (!observedComplex) {
            return;
        }
        const { complex: uid } = observedComplex.dataset;
        const marker = markersByUid[uid] || null;
        if (marker && !marker.element.classList.contains('hidden')) {
            setActiveMarker(marker);
        }
    };

    const observeComplexes = () => {
        if (complexObserver) {
            complexObserver.disconnect();
        }
        observedComplexes = [];
        const complexes = $el.find('[data-complex]').get();
        if (!complexes.length) {
            return;
        }
        complexObserver = new IntersectionObserver(onComplexesObserve);
        complexes.forEach(complex => complexObserver.observe(complex));
    };

    const updateHtml = html => {

        const $html = $('<div/>')
            .html(html);

        // Update stays
        const $staysContainer = $el.find('[data-stays]')
            .eq(0);
        const $newStaysContainer = $html.find('[data-stays]')
            .eq(0);
        if ($staysContainer.length && $newStaysContainer.length) {
            const staysContainer = $staysContainer.get(0);
            gsap.timeline()
                .to(staysContainer, {
                    opacity: 0,
                    duration: 0.3
                })
                .add(() => {
                    Components.destroy(staysContainer);
                    $staysContainer.html($newStaysContainer.html());
                    Components.init(staysContainer);
                    setTimeout(observeComplexes, 0);
                })
                .to(staysContainer, {
                    opacity: 1,
                    duration: 0.3
                })
                .set(staysContainer, { clearProps: 'all' });
        }

        // Update map and listing sliders
        const newMapContainer = $html.find('[data-map]').get(0);
        if (mapContainer && newMapContainer) {
            try {
                const $newMapListings = $html.find('[data-map-listings]').eq(0);
                if (mapListings && $newMapListings.length) {
                    $(mapListings).html($newMapListings.html());
                }
                const mapProps = newMapContainer.getAttribute('data-map');
                mapContainer.setAttribute('data-map', mapProps);
                const { markers } = JSON.parse(mapProps);
                setActiveMarker(null);
                addMarkersToMap(markers || []);
            } catch (error) {
                console.error(error);
            }
        }

        // Update announcer
        const newAnnouncer = $html.find('#stays-listing-announcer').get(0);
        if (announcer && newAnnouncer) {
            announcer.textContent = newAnnouncer.textContent;
        }

    };

    const getQueryParams = () => serialize(searchForm, true);

    const updateQueryParams = (params = {}) => {

        // Update query string
        let url = window.location.href.split('?')[0];

        let queryParams = {
            ...getQueryParams(),
            ...params
        };

        queryParams = Object.keys(queryParams)
            .reduce((carry, key) => (queryParams[key] ? {
                ...carry,
                [key]: queryParams[key]
            } : carry), {});

        let queryString = '';

        if (Object.keys(queryParams).length) {
            queryString = new URLSearchParams(queryParams).toString();
        }

        if (queryString) {
            url += `?${queryString}`;
        }

        window.history.pushState(null, '', url);

        return queryString;

    };

    const makeAjaxRequest = () => superagent
        .get(window.location.href)
        .set('Accept', 'text/html')
        .query(getQueryParams())
        .then(({
            status,
            text: html
        }) => {
            if (status !== 200 || !html) {
                throw new Error();
            }
            updateHtml(html);
        })
        .catch(error => {
            console.warn(error);
        });

    const onSearchFormSubmit = e => {

        e.preventDefault();

        if (request) {
            return;
        }

        const submitBtn = searchForm.querySelector('button[type="submit"]');
        submitBtn.classList.add('loading');

        updateQueryParams();

        // Update announcer
        if (announcer) {
            announcer.textContent = announcer.dataset.searchText;
        }

        request = makeAjaxRequest();

        request
            .then(() => {
                submitBtn.classList.remove('loading');
                request = null;
            });

    };

    const onSortMenuClick = e => {

        e.preventDefault();

        if (request) {
            return;
        }

        const { triggerTarget: link } = e;

        const { href } = link;
        const queryParams = new URLSearchParams(href.split('?')
            .pop());
        const sortBy = queryParams.get('sortBy') || null;
        const query = updateQueryParams({ sortBy });

        sortMenu.classList.add('loading');

        request = superagent.get(window.location.href);
        request
            .type('form')
            .set('Accept', 'text/html')
            .query(query)
            .then(({
                status,
                text: html
            }) => {
                if (status !== 200 || !html) {
                    throw new Error();
                }
                updateHtml(html);
            })
            .catch(error => {
                console.warn(error);
            })
            .then(() => {
                request = null;
                sortMenu.classList.remove('loading');
            });

    };

    const onBreakpoint = () => {
        setTimeout(() => {
            createListingSlider(activeMarker);
        }, 0);
    };

    const onResize = () => {
        const { width, height } = Viewport;
        if (width === viewportWidth && height === viewportHeight) {
            return;
        }
        viewportWidth = width;
        viewportHeight = height;
        fitMapToMarkers();
        if (listingSlider) {
            listingSlider.resize();
        }
    };

    const onProgrammaticScrollStart = () => {
        suppressComplexObserver = true;
    };

    const onProgrammaticScrollEnd = () => {
        setTimeout(() => {
            suppressComplexObserver = false;
        }, 0);
    };

    const init = () => {
        if (viewToggle) {
            viewToggle.addEventListener('click', onViewToggleClick);
        }
        if (mapContainer) {
            mapObserver = new IntersectionObserver(([{ isIntersecting }]) => {
                if (!isIntersecting) {
                    return;
                }
                mapObserver.disconnect();
                mapObserver = null;
                initMap();
            });
            mapObserver.observe(mapContainer);
        }
        if (searchForm) {
            searchForm.addEventListener('submit', onSearchFormSubmit);
        }
        $el.on('click', '[data-sortmenu] a', onSortMenuClick);
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        Dispatch.on(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
        Dispatch.on(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);
        observeComplexes();
        loadFlickity(module => {
            Flickity = module.default;
            console.info('flickity loaded');
            if (activeMarker) {
                createListingSlider(activeMarker);
            }
        });
    };

    const destroy = () => {
        if (viewToggle) {
            viewToggle.removeEventListener('click', onViewToggleClick);
        }
        if (mapObserver) {
            mapObserver.disconnect();
            mapObserver = null;
        }
        if (searchForm) {
            searchForm.removeEventListener('submit', onSearchFormSubmit);
        }
        if (complexObserver) {
            complexObserver.disconnect();
            complexObserver = null;
        }
        $el.off('click');
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        Dispatch.off(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
        Dispatch.off(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);
        killListingSlider();
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
