import gsap from 'gsap';
import { loadFuse } from '../lib/async-bundles';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default (el, props) => {

    const dom = $(el);
    const status = dom.find('[data-status]');
    const list = dom.find('[data-list]');
    const form = dom.find('[data-form]');
    const buttons = dom.find('[aria-expanded]');
    const searchField = dom.find('[data-search]');
    const items = dom.find('[data-item]');
    const clear = dom.find('[type="reset"]');

    const master = items.map(item => ({
        node: item,
        question: $(item).find('[data-question]').text(),
        answer: $(item).find('[data-answer]').text()
    }));

    let lastQuery = '';

    let fuse = null;

    const open = button => {
        const content = button.get(0).nextElementSibling;
        const chevron = button.find('svg').get(0);
        button.attr('aria-expanded', 'true');
        button.attr('aria-label', props.hideAnswer);
        gsap.set(content, { height: 'auto' });
        gsap.from(content, {
            height: 0,
            duration: 0.35,
            ease: 'power2.inOut',
            onComplete: () => {
                gsap.set(content, { height: 'auto' });
            }
        });
        gsap.to(chevron, { scaleY: -1, duration: 0.35, ease: 'power2.inOut' });
    };

    const close = button => {
        const content = button.get(0).nextElementSibling;
        const chevron = button.find('svg').get(0);
        button.attr('aria-expanded', 'false');
        button.attr('aria-label', props.showAnswer);
        gsap.to(content, { height: 0, duration: 0.35, ease: 'power2.inOut', clearProps: 'height' });
        gsap.to(chevron, { scaleY: 1, duration: 0.35, ease: 'power2.inOut' });
    };

    const toggle = e => {
        const target = $(e.triggerTarget);
        if (target.attr('aria-expanded') === 'true') {
            close(target);
        } else {
            open(target);
        }
    };

    const resetSearch = () => {
        lastQuery = '';
        clear.attr('aria-disabled', 'true');
        status.text('');
        list.empty().append(master.map(item => item.node));
    };

    const cancelSubmit = e => {
        e.preventDefault();
        return false;
    };

    const onSearch = () => {
        const query = searchField.val().trim();
        if (lastQuery !== query) {
            lastQuery = query;
            if (query === '') {
                resetSearch();
            } else {
                const raw = fuse.search(query);
                const result = raw.map(r => r.item.node);
                clear.attr('aria-disabled', 'false');
                if (result.length > 0) {
                    status.text(props.matchesMessage.replace('%QUERY%', query).replace('%COUNT%', result.length));
                    list.empty().append(result);
                } else {
                    const noMatchMessage = props.noMatchMessage.replace('%QUERY%', query);
                    status.text(noMatchMessage);
                    list.empty().append(`<li class="w-full intro font-bold text-center">${noMatchMessage}</li>`);
                }
            }
        }
    };

    const init = () => {
        loadFuse(({ default: Fuse }) => {
            fuse = new Fuse(master, {
                includeScore: true,
                shouldSort: true,
                threshold: 0.2,
                ignoreLocation: true,
                keys: ['question', 'answer']
            });
            form.on('reset', resetSearch);
            form.on('submit', cancelSubmit);
            buttons.on('click', toggle);
            searchField.on('keyup', onSearch);
        });
    };

    const destroy = () => {
        form.off('reset', resetSearch);
        form.off('submit', cancelSubmit);
        buttons.off('click', toggle);
        searchField.off('keyup', onSearch);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };
};
