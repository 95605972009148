import ChoicesSelect from '../lib/ChoicesSelect';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const dom = $(el);
    const select = dom.find('select').get(0);

    let choices;

    const init = () => {
        choices = ChoicesSelect(select, {
            searchEnabled: true,
            shouldSort: false,
            allowHTML: true,
            searchResultLimit: 8,
            searchPlaceholderValue: 'Type to search',
            searchFields: ['label', 'value', 'customProperties'],
            callbackOnCreateTemplates: template => {
                return {
                    choice: ({ classNames }, data) => {
                        return template(`<div class="${classNames.item} ${classNames.itemChoice} ${
                            data.disabled ? classNames.itemDisabled : classNames.itemSelectable
                        }" data-select-text="lorem ipsum" data-choice ${
                            data.disabled
                                ? 'data-choice-disabled aria-disabled="true"'
                                : 'data-choice-selectable'
                        } data-id="${data.id}" data-value="${data.value}" ${
                            data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                        }>${data.customProperties}</div>`);
                    }
                };
            }
        });
    };

    const destroy = () => {
        if (choices) {
            choices.destroy();
            choices = null;
        }
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };
};
