import gsap from 'gsap';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';
import Viewport from '../core/Viewport';
import { clearTimelineProps } from './helpers';

gsap.registerPlugin(MorphSVGPlugin);

export default el => {

    const outer = el.firstElementChild;
    const inner = outer.firstElementChild;
    const ball = inner.firstElementChild;
    const form = el.querySelector('form');
    const svgCircle = el.querySelector('[data-circle]');
    const svgCirclePath = svgCircle.querySelector('path');
    const svgCircleShadow = svgCircle.querySelector('feDropShadow');
    const toggleBtn = el.querySelector('button[aria-expanded]');
    const toggleBtnInner = toggleBtn.firstElementChild;

    const INITIAL_SHADOW_OPACITY = parseFloat(svgCircleShadow.getAttribute('flood-opacity'));
    const shadowSetter = gsap.quickSetter(svgCircleShadow, 'flood-opacity');

    const CIRCLE_SHAPE = 'M36,181a160,160 0 1,0 320,0a160,160 0 1,0 -320,0';
    const BLOB_SHAPE_SCROLL_DOWN = 'M318.666 161.71c0-39.226-65.82-25.791-89.25-53.62-29.35-34.863-105.098-97.956-154.237-97.956-88.365 0-56.65 38.753-56.65 127.118 0 40.438 32.607 73.426 57.348 101.595 29.324 33.386 34.861 82.863 82.789 82.863 88.365 0 160-71.634 160-160Z';
    const BLOB_SHAPE_SCROLL_UP = 'M269.322 71.573c33.971 19.613 16.022 60.513 28.408 94.718 15.517 42.85 114.16 104.718 89.59 147.274-44.182 76.527-110.924 97.773-187.45 53.59-35.02-20.218-79.326-107.216-91.35-142.727-14.252-42.088-51.359-71.673-27.395-113.18 44.183-76.526 111.67-83.858 188.197-39.675Z';
    const BLOB_SHAPE_REVEAL = 'M160 323.863c88.366 0 225.977-71.634 225.977-160 0-88.365-145.301-135.558-225.977-160C110.705-11.07 61.915 18.99 31.417 64.794c-19.415 29.16 0 56.51-12.3 97.138-14.518 47.955-3.176 81.577 22.941 110.052 29.249 31.888 71.26 51.88 117.942 51.88Z';
    const BLOB_SHAPE_HIDE = 'M195.082 335.46c56.86 0 90.16-23.265 141.21-67.948 22.24-19.465 36.33-54.14 36.33-85.645 0-39.392-33.05-60.878-47.11-94.313-20.028-47.62-63.605-16.78-112.579-16.78-48.359 0-104.648-21.794-132.858 6.457-26.212 26.249-38.954 54.534-20.833 104.636 18.292 50.575 7.195 77.77 49.043 130.328 19.77 24.83 52.185 23.266 86.797 23.266Z';

    let prevScrollTop = Viewport.scrollTop;
    let prevScrollDirection = null;
    let isCollapsed = false;
    let hasScrolled = false;

    let collapseBallContentTween;
    let showShadowTween;

    let innerX = null;
    let innerY = null;

    const getInitialValues = () => {
        reset();
        innerX = gsap.getProperty(inner, 'x', '%');
        innerY = gsap.getProperty(inner, 'y', '%');
    };

    const createCollapseBallContentTween = () => {
        if (collapseBallContentTween) {
            return;
        }
        collapseBallContentTween = gsap.timeline({ paused: true })
            .to(form, {
                opacity: 0,
                duration: 0.3
            }, 0)
            .to(form, {
                y: '5%',
                scale: 0.75,
                transformOrigin: 'right center',
                duration: 0.35,
                ease: 'Back.easeInOut'
            }, 0)
            .to(toggleBtn, {
                opacity: 1,
                duration: 0.3,
                ease: 'Cubic.easeIn'
            }, 0.2)
            .to(toggleBtnInner, {
                scale: 2,
                x: '45%',
                transformOrigin: 'left center',
                duration: 0.5,
                ease: 'Back.easeInOut'
            }, 0);
    };

    const createShowShadowTween = () => {
        if (showShadowTween) {
            return;
        }
        shadowSetter(INITIAL_SHADOW_OPACITY);
        const shadowObject = { floodOpacity: 0 };
        showShadowTween = gsap.timeline({
            paused: true,
            onUpdate() {
                shadowSetter(shadowObject.floodOpacity);
            }
        })
            .fromTo(shadowObject, {
                floodOpacity: 0
            }, {
                floodOpacity: INITIAL_SHADOW_OPACITY,
                duration: 1,
                ease: 'none',
                onUpdate() {
                    shadowSetter(this.targets()[0].floodOpacity);
                }
            });
    };

    const getIsCollapsed = () => isCollapsed;

    const getIsCollapsible = () => !isCollapsed && hasScrolled;

    const hide = (tween = true) => {
        if (isCollapsed || !getIsCollapsible()) {
            return;
        }
        el.classList.add('is-collapsed');
        toggleBtn.setAttribute('aria-expanded', 'false');
        isCollapsed = true;
        if (!document.activeElement || el.contains(document.activeElement)) {
            toggleBtn.focus();
        }
        if (tween) {
            const tl = gsap.timeline({ paused: true })
                .to(inner, {
                    x: '50%',
                    duration: 1,
                    ease: 'Back.easeOut'
                }, 0)
                .to(ball, {
                    scale: 0.5,
                    duration: 1,
                    ease: 'Back.easeOut'
                }, 0)
                .to(collapseBallContentTween, {
                    progress: 1,
                    duration: 1,
                    ease: 'none'
                }, 0)
                .to(svgCirclePath, {
                    morphSVG: BLOB_SHAPE_HIDE,
                    duration: 0.5,
                    ease: 'Quad.easeIn'
                }, 0)
                .to(svgCirclePath, {
                    morphSVG: CIRCLE_SHAPE,
                    duration: 0.5,
                    ease: 'Quad.easeOut'
                }, 0.5);
            gsap.to(tl, {
                progress: 1,
                duration: 0.5,
                ease: 'none'
            });
        }
    };

    const reveal = (tween = true) => {
        if (!isCollapsed) {
            return;
        }
        el.classList.remove('is-collapsed');
        toggleBtn.setAttribute('aria-expanded', 'true');
        isCollapsed = false;
        if (tween) {
            const tl = gsap.timeline({ paused: true })
                .to(inner, {
                    x: innerX,
                    duration: 1,
                    ease: 'Back.easeOut'
                }, 0)
                .to(ball, {
                    scale: 1,
                    duration: 1,
                    ease: 'Back.easeOut'
                }, 0)
                .to(collapseBallContentTween, {
                    progress: 0,
                    duration: 0.75,
                    ease: 'Quad.easeOut'
                }, 0)
                .to(svgCirclePath, {
                    morphSVG: {
                        shape: BLOB_SHAPE_REVEAL,
                        type: 'rotational'
                    },
                    duration: 0.3,
                    ease: 'none'
                }, 0)
                .to(svgCirclePath, {
                    morphSVG: {
                        shape: CIRCLE_SHAPE,
                        type: 'rotational'
                    },
                    duration: 0.75,
                    ease: 'Quad.easeOut'
                }, 0.3);
            gsap.to(tl, {
                progress: 1,
                duration: 0.65,
                ease: 'none'
            });
        }
    };

    const reset = () => {
        const elements = [outer, inner, ball, form, toggleBtn, toggleBtnInner, svgCircle];
        elements.forEach(element => {
            gsap.killTweensOf(element);
            gsap.set(element, { clearProps: 'all' });
        });
        if (collapseBallContentTween) {
            collapseBallContentTween.kill();
            clearTimelineProps(collapseBallContentTween);
            collapseBallContentTween = null;
        }
        if (showShadowTween) {
            showShadowTween.kill();
            showShadowTween = null;
        }
        shadowSetter(INITIAL_SHADOW_OPACITY);
        reveal(false);
    };

    const scrollHandler = (force = false, tween = true, scrollDirection = null) => {
        const {
            scrollTop,
            height: viewportHeight
        } = Viewport;
        const shouldUpdate = force || (scrollTop < (viewportHeight - 20) || Math.abs(scrollTop - prevScrollTop) >= 30);
        if (!shouldUpdate) {
            return;
        }
        const direction = scrollDirection || (scrollTop >= prevScrollTop ? 'down' : 'up');
        prevScrollDirection = direction;
        const offset = direction === 'down' ? 0.72 : 1;
        const hadScrolled = hasScrolled;
        hasScrolled = scrollTop > (viewportHeight * offset);
        if (hasScrolled !== hadScrolled) {
            let tl;
            if (hasScrolled) {

                hide(false);

                tl = gsap.timeline({ paused: true })
                    .to(outer, {
                        y: '25vh',
                        duration: 1,
                        ease: 'Back.easeOut'
                    }, 0)
                    .to(inner, {
                        x: '50%',
                        y: '-50%',
                        duration: 1,
                        ease: 'Back.easeOut'
                    }, 0)
                    .to(ball, {
                        scale: 0.5,
                        duration: 1,
                        ease: 'Back.easeOut'
                    }, 0)
                    .to(collapseBallContentTween, {
                        progress: 1,
                        duration: 1,
                        ease: 'none'
                    }, 0)
                    .to(svgCirclePath, {
                        morphSVG: {
                            shape: BLOB_SHAPE_SCROLL_DOWN,
                            type: 'rotational'
                        },
                        duration: 0.5,
                        ease: 'none'
                    }, 0)
                    .to(svgCirclePath, {
                        morphSVG: {
                            shape: CIRCLE_SHAPE,
                            type: 'rotational'
                        },
                        duration: 0.5,
                        ease: 'none'
                    }, 0.5)
                    .to(showShadowTween, {
                        progress: 1,
                        duration: 1,
                        ease: 'none'
                    }, 0);

            } else {

                reveal(false);

                tl = gsap.timeline({ paused: true })
                    .to(outer, {
                        y: 0,
                        duration: 1,
                        ease: 'Back.easeOut'
                    }, 0)
                    .to(inner, {
                        x: innerX,
                        y: innerY,
                        duration: 1,
                        ease: 'Back.easeOut'
                    }, 0)
                    .to(ball, {
                        scale: 1,
                        duration: 1,
                        ease: 'Back.easeOut'
                    }, 0)
                    .to(collapseBallContentTween, {
                        progress: 0,
                        duration: 1,
                        ease: 'none'
                    }, 0)
                    .to(svgCirclePath, {
                        morphSVG: {
                            shape: BLOB_SHAPE_SCROLL_UP,
                            type: 'rotational'
                        },
                        duration: 0.3,
                        ease: 'none'
                    }, 0)
                    .to(svgCirclePath, {
                        morphSVG: {
                            shape: CIRCLE_SHAPE,
                            type: 'rotational'
                        },
                        duration: 0.75,
                        ease: 'none'
                    }, 0.3)
                    .to(showShadowTween, {
                        progress: 0,
                        duration: 1,
                        ease: 'none'
                    }, 0);

            }

            requestAnimationFrame(() => {
                if (tween) {
                    gsap.to(tl, {
                        progress: 1,
                        duration: 0.65,
                        ease: 'Power1.easeInOut'
                    });
                } else {
                    tl.progress(1);
                }
            });

        } else {

            hide(tween);

        }
        prevScrollTop = scrollTop;
    };

    const resizeHandler = () => {
        hasScrolled = null;
        scrollHandler(true, false, prevScrollDirection);
    };

    const onBreakpoint = () => {
        getInitialValues();
        createCollapseBallContentTween();
        createShowShadowTween();
    };

    const onResize = () => {
        resizeHandler();
    };

    const onScroll = () => {
        scrollHandler();
    };

    const init = () => {
        getInitialValues();
        createCollapseBallContentTween();
        createShowShadowTween();
        resizeHandler();
        scrollHandler(true);
        // gsap.fromTo(el, { opacity: 0 }, {
        //     opacity: 1,
        //     duration: 0.3
        // });
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onBreakpoint);
        reset();
    };

    return {
        init,
        destroy,
        reveal,
        hide,
        getIsCollapsible,
        getIsCollapsed
    };
};
