import gsap from 'gsap';
import { loadBricks } from '../lib/async-bundles';
import Viewport from '../core/Viewport';
import $ from '../core/Dom';
import request from '../core/request';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {
    const dom = $(el);
    const wrapper = dom.find('[data-bricks]');
    const topFilters = dom.find('[data-top-filters] [data-filter]');
    let filters = dom.find('[data-filter]');
    let items = dom.find('[data-item]');
    let bricks = null;
    let hasPushedState = false;

    const reset = () => {
        wrapper.attr('style', null);
        items.attr('style', null);
    };

    const onResize = () => {
        reset();
        bricks.pack();
    };

    const initFilters = () => {
        filters.off('click', onFilter);
        filters = dom.find('[data-filter]');
        filters.attr('role', 'button');
        filters.on('click', onFilter);
    };

    const setActiveFilter = filter => {
        topFilters.find('span').removeClass('is-active');
        topFilters.filter(`[data-filter="${filter}"]`).find('span').addClass('is-active');
    };

    const revealItems = () => {
        gsap.to(items.nodes, {
            duration: 0.375,
            ease: 'power2.inOut',
            y: 0,
            opacity: 1,
            stagger: 0.0225
        });
    };

    const onFilter = e => {
        e.preventDefault();
        setActiveFilter($(e.triggerTarget).data('filter'));
        const url = e.triggerTarget.href;
        request.get(url).then(res => {
            const { status, text } = res || {};
            if (status === 200 && text) {
                if (window.history) {
                    if (hasPushedState) {
                        window.history.replaceState(null, document.title, url);
                    } else {
                        window.history.pushState(null, document.title, url);
                    }
                    hasPushedState = true;
                }
                const loadedItems = $(text).find('[data-item]').nodes;
                const oldItems = items.nodes;
                gsap.to(oldItems, {
                    duration: 0.375,
                    ease: 'power2.inOut',
                    y: 25,
                    opacity: 0,
                    stagger: 0.0225,
                    onComplete: () => {
                        gsap.set(loadedItems, { y: 25, opacity: 0 });
                        wrapper.empty().append(loadedItems);
                        bricks.pack();
                        items = dom.find('[data-item]');
                        initFilters();
                        revealItems();
                    }
                });
            } else {
                console.error(res);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const init = () => {
        loadBricks(({ default: Bricks }) => {
            bricks = Bricks({
                container: wrapper.get(0),
                packed: 'data-packed',
                sizes: [
                    { columns: 1, gutter: 0 },
                    { mq: '750px', columns: 2, gutter: 0 },
                    { mq: '1200px', columns: 3, gutter: 0 }
                ]
            });
            bricks.pack();
            initFilters();
            Viewport.on('resize', onResize);
        });
    };

    const destroy = () => {
        filters.off('click', onFilter);
        Viewport.off('resize', onResize);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
