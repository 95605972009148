import gsap from 'gsap';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, MENU_CLOSE, MENU_OPEN } from '../lib/events';

export default el => {

    const burger = el.querySelector('button');
    const menu = burger.nextElementSibling;

    let menuIsOpen = false;
    let menuTl;

    const openMenu = (tween = true) => {
        if (menuIsOpen) {
            return;
        }
        menuIsOpen = true;
        burger.setAttribute('aria-expanded', 'true');
        Dispatch.emit(MENU_OPEN);
        if (menuTl) {
            menuTl.kill();
        }
        menu.hidden = false;
        if (tween) {
            menuTl = gsap.timeline({
                onComplete() {
                    menuTl.kill();
                    menuTl = null;
                }
            })
                .to(menu, {
                    opacity: 1,
                    duration: 0.3
                }, 0);
        } else {
            gsap.set(menu, { opacity: 1 });
        }
        Viewport.lockTabbing(el, burger);
    };

    const closeMenu = (tween = true) => {
        if (!menuIsOpen) {
            return;
        }
        menuIsOpen = false;
        burger.setAttribute('aria-expanded', 'false');
        let { activeElement } = document;
        if (!activeElement || el.contains(activeElement)) {
            activeElement = burger;
        }
        Viewport.releaseTabbing(activeElement);
        if (menuTl) {
            menuTl.kill();
        }
        if (tween) {
            menuTl = gsap.timeline({
                onComplete() {
                    menuTl.kill();
                    menuTl = null;
                    menu.hidden = true;
                    Dispatch.emit(MENU_CLOSE);
                }
            })
                .to(menu, {
                    opacity: 0,
                    duration: 0.3
                });
        } else {
            menu.hidden = true;
            gsap.set(menu, { opacity: 0 });
            Dispatch.emit(MENU_CLOSE);
        }
    };

    const toggleMenu = () => {
        if (menuIsOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const onBurgerClick = () => {
        toggleMenu();
    };

    const onBodyKeyUp = e => {
        if (e.key === 'Escape') {
            closeMenu();
        }
    };

    const onMenuItemClick = () => {
        closeMenu();
    };

    const destroy = () => {
        if (menuTl) {
            menuTl.kill();
            menuTl = null;
        }
        closeMenu(false);
        burger.removeEventListener('click', onBurgerClick);
        menu.querySelectorAll('a').forEach(link => link.removeEventListener('click', onMenuItemClick));
        document.body.removeEventListener('keyup', onBodyKeyUp);
    };

    const init = () => {
        gsap.set(menu, { opacity: 0 });
        burger.addEventListener('click', onBurgerClick);
        menu.querySelectorAll('a').forEach(link => link.addEventListener('click', onMenuItemClick));
        document.body.addEventListener('keyup', onBodyKeyUp);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
