import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import {
    COMPONENT_INIT,
    MENU_CLOSE,
    MENU_OPEN,
    PROGRAMMATIC_SCROLL_END,
    PROGRAMMATIC_SCROLL_START
} from '../lib/events';

export default (el, props) => {

    const $el = $(el);
    const inner = el.firstElementChild;
    const star = el.querySelector('[data-star]');
    const { sticky: canBeSticky = true } = props || {};
    const isPermanentlySticky = canBeSticky && inner.classList.contains('fixed');

    let {
        scrollTop: prevScrollTop,
        width: viewW,
        height: viewH
    } = Viewport;

    let height = $(inner).height();
    let { top: offsetTop } = $el.offset();

    let isSticky = isPermanentlySticky;
    let isHidden = false;
    let menuIsOpen = false;
    let preventShow = false;

    let mutationObserver;
    let scrollRaf;

    let starTl = null;

    const spinStar = () => {
        if (starTl || !star || star.offsetParent === null) {
            return;
        }
        starTl = gsap.timeline({
            onComplete() {
                starTl = null;
            }
        })
            .to(star, { rotate: 360, ease: 'Quint.easeInOut', duration: 1 })
            .set(star, { clearProps: 'transform' });
    };

    const show = (tween = true) => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        gsap.killTweensOf(inner);
        if (tween) {
            gsap.timeline()
                .to(inner, {
                    yPercent: 0,
                    duration: 0.3
                }, 0);
            spinStar();
        } else {
            gsap.set(inner, { yPercent: 0 });
        }
    };

    const hide = (tween = true) => {
        if (isHidden) {
            return;
        }
        isHidden = true;
        gsap.killTweensOf(inner);
        if (tween) {
            gsap.to(inner, {
                yPercent: -100,
                duration: 0.3
            });
        } else {
            gsap.set(inner, { yPercent: -100 });
        }
    };

    const stick = () => {
        if (isSticky || isPermanentlySticky) {
            return;
        }
        isSticky = true;
        inner.classList.replace('absolute', 'fixed');
        gsap.set(inner, { top: offsetTop });
    };

    const unstick = () => {
        if (!isSticky || isPermanentlySticky) {
            return;
        }
        isSticky = false;
        inner.classList.replace('fixed', 'absolute');
        gsap.set(inner, { clearProps: 'top' });
    };

    const onScroll = (tween = true, force = false) => {
        const { scrollY: scrollTop } = window;
        let direction = 'up';
        if (prevScrollTop) {
            direction = scrollTop > prevScrollTop ? 'down' : 'up';
        }
        // Make sticky?
        const top = Math.round(scrollTop);
        if (top >= viewH * 0.5) {
            inner.classList.add('scrolled');
        } else {
            inner.classList.remove('scrolled');
        }
        if (isSticky) {
            if ((isHidden && top <= height) || top <= 0) {
                unstick();
                show(false);
                return;
            }
            if (menuIsOpen) {
                show(false);
            }
            if (!force && Math.abs(top - prevScrollTop) <= 20) {
                return;
            }
            const threshold = isPermanentlySticky ? (viewH / 3) : height;
            if (direction === 'down' && top >= threshold) {
                hide(tween);
            } else if (direction === 'up' && scrollTop >= viewH && !preventShow) { // scrollTop < maxScroll
                show(tween);
            }
        } else if (top >= height) {
            stick();
            hide(false);
            return;
        }
        prevScrollTop = scrollTop;
    };

    const onResize = (updateScroll = true, force = false) => {
        if (!force && Viewport.width === viewW && Math.abs(Viewport.height - viewH) < 150) { // && getMaxScroll() === maxScroll
            return;
        }
        viewW = Viewport.width;
        viewH = Viewport.height;
        height = $(inner)
            .height();
        offsetTop = $el.offset().top;
        //maxScroll = getMaxScroll();
        if (updateScroll) {
            onScroll(force);
        }
    };

    const scrollHandler = () => {
        if (scrollRaf) {
            cancelAnimationFrame(scrollRaf);
        }
        scrollRaf = requestAnimationFrame(() => {
            scrollRaf = null;
            onScroll();
        });
    };

    const onMenuOpen = () => {
        if (menuIsOpen) {
            return;
        }
        document.body.classList.add('menu-open');
        menuIsOpen = true;
        show(false);
        if (!isSticky && Viewport.scrollTop > 0) {
            gsap.to(window, {
                scrollTo: 0,
                duration: 0.3
            });
        }
    };

    const onMenuClose = () => {
        if (!menuIsOpen) {
            return;
        }
        setTimeout(() => {
            document.body.classList.remove('menu-open');
            menuIsOpen = false;
        }, 100);
    };

    const onProgrammaticScrollStart = () => {
        if (!isHidden || !isSticky) {
            return;
        }
        preventShow = true;
    };

    const onProgrammaticScrollEnd = () => {
        preventShow = false;
    };

    const destroy = () => {

        Dispatch.off(MENU_OPEN, onMenuOpen);
        Dispatch.off(MENU_CLOSE, onMenuClose);

        if (!canBeSticky) {
            return;
        }

        $(el).off('focusin click');

        window.removeEventListener('scroll', scrollHandler);
        Viewport.off('resize', onResize);

        Dispatch.off(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
        Dispatch.off(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);

        if (mutationObserver) {
            mutationObserver.disconnect();
            mutationObserver = null;
        }
    };

    const init = () => {

        Dispatch.on(MENU_OPEN, onMenuOpen);
        Dispatch.on(MENU_CLOSE, onMenuClose);

        if (!canBeSticky) {
            return;
        }

        $(el).on('focusin', 'a,button', show);

        window.addEventListener('scroll', scrollHandler);
        Viewport.on('resize', onResize);

        onResize(false, true);
        onScroll(false, true);

        Dispatch.on(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
        Dispatch.on(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);

        if (window.MutationObserver) {
            mutationObserver = new MutationObserver(() => {
                onResize();
            });
            mutationObserver.observe(document.body, {
                attributes: false,
                childList: true,
                subtree: true
            });
        }

    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
