import gsap from "gsap";
import { loadFlatpickr } from '../lib/async-bundles';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default (el, props) => {

    const input = el.querySelector('input');
    const fromValue = el.querySelector('[data-from]');
    const toValue = el.querySelector('[data-to]');
    const shader = document.querySelector('[data-flatpickr-shader]');

    const {
        fromName = '',
        toName = '',
        fromPlaceholder = '',
        toPlaceholder = '',
        minDate = null,
        maxDate = null
    } = props || {};

    let Flatpickr;
    let picker;
    let showMonths = 1;
    let isOpen = false;

    const fromInput = el.querySelector(`input[type="hidden"][name="${fromName}"]`);
    const toInput = el.querySelector(`input[type="hidden"][name="${toName}"]`);

    const defaultFromDate = fromInput.value;
    const defaultToDate = toInput.value;
    const defaultDate = [];
    if (defaultFromDate) {
        defaultDate.push(defaultFromDate);
        if (defaultToDate) {
            defaultDate.push(defaultToDate);
        }
    }

    const destroyPickr = () => {
        if (picker) {
            picker.destroy();
            picker = null;
        }
    };

    const initPickr = () => {
        destroyPickr();
        showMonths = Viewport.width >= 650 ? 2 : 1;
        picker = Flatpickr(input, {
            showMonths,
            allowInput: false,
            disableMobile: false,
            mode: 'range',
            dateFormat: 'd.m.Y',
            defaultDate,
            prevArrow: '<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M-0.000734806 8.372V5.6L6.91527 -9.53674e-07L7.44727 0.363999L4.36727 6.972L7.44727 13.608L6.91527 13.972L-0.000734806 8.372Z" fill="currentColor"/></svg>',
            nextArrow: '<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.448 5.6L7.448 8.372L0.532 13.972L0 13.608L3.08 7L0 0.363999L0.532 0L7.448 5.6Z" fill="currentColor"/></svg>',
            onChange(selectedDates) {
                const [fromDate, toDate] = selectedDates;
                fromInput.value = fromDate ? picker.formatDate(fromDate, 'Y-m-d') : '';
                toInput.value = fromDate && toDate ? picker.formatDate(toDate, 'Y-m-d') : '';
                fromValue.textContent = fromDate ? picker.formatDate(fromDate, 'd.m.Y') : fromPlaceholder;
                toValue.textContent = fromDate && toDate ? picker.formatDate(toDate, 'd.m.Y') : toPlaceholder;
            },
            onOpen() {
                isOpen = true;
                gsap.set(shader, { opacity: 0 });
                shader.removeAttribute('hidden');
                gsap.to(shader, { opacity: 0.25, duration: 0.3, ease: 'power2.inOut' });

            },
            onClose() {
                isOpen = false;
                gsap.to(shader, {
                    opacity: 0,
                    duration: 0.3,
                    ease: 'power2.out',
                    onComplete: () => {
                        shader.setAttribute('hidden', '');
                    }
                });
            },
            minDate,
            maxDate
        });
        if (isOpen) {
            picker.open();
        }
    };

    const onResize = () => {
        const vw = Viewport.width;
        if (vw >= 648 && showMonths !== 2) {
            initPickr();
        } else if (vw < 648 && showMonths !== 1) {
            initPickr();
        }
    };

    loadFlatpickr(({ default: flatpickr }) => {
        Flatpickr = flatpickr;
        initPickr();
        Viewport.on('resize', onResize);
    });

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        destroy() {
            Viewport.on('resize', onResize);
            destroyPickr();
        }
    };

};
