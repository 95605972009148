import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { PROGRAMMATIC_SCROLL_END, PROGRAMMATIC_SCROLL_START } from './events';

gsap.registerPlugin(ScrollToPlugin);

/**
 * Completely resets all nodes in a GSAP timeline
 *
 * @param timeline
 * @param clearProps
 */
export const clearTimelineProps = (timeline, clearProps = 'all') => {
    const children = timeline.getChildren();
    children.forEach(child => {
        if (typeof child.targets === 'function') {
            const targets = child.targets().filter(target => typeof target !== 'function');
            if (!targets.length) {
                return;
            }
            gsap.set(targets, { clearProps });
        } else {
            clearTimelineProps(child, clearProps);
        }
    });
};

/**
 * @returns {boolean}
 */
export const isTouch = () => !document.documentElement.classList.contains('using-mouse');

/**
 * @returns {boolean}
 */
export const isLivePreview = () => document.documentElement.classList.contains('is-preview') && document.documentElement.classList.contains('is-iframe');

/**
 * @returns {boolean}
 */
export const reduceMotion = () => window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)').matches;

/**
 * @param y
 * @returns {gsap.core.Tween}
 */
export const animatedScroll = (y, vars = {}, offsetY = 0) => {
    let scrollMarginTop = 0;
    if (y instanceof HTMLElement) {
        scrollMarginTop = parseInt((window.getComputedStyle(y).scrollMarginTop || '0').replace('px', ''), 10) || 0;
    }
    let onComplete;
    if (vars.hasOwnProperty('onComplete')) {
        onComplete = vars.onComplete;
        delete vars.onComplete;
    }
    return gsap.to(window, {
        scrollTo: {
            y,
            offsetY: Math.max(0, offsetY + scrollMarginTop),
            autoKill: !isTouch(),
            onAutoKill() {
                Dispatch.emit(PROGRAMMATIC_SCROLL_END);
            }
        },
        duration: 1,
        ease: 'Power2.easeInOut',
        //ease: 'Power2.easeInOut',
        //ease: 'Cubic.easeInOut',
        onStart() {
            Dispatch.emit(PROGRAMMATIC_SCROLL_START);
        },
        onComplete() {
            Dispatch.emit(PROGRAMMATIC_SCROLL_END);
            if (y instanceof HTMLElement && (y.tagName === 'A' || y.tagName === 'BUTTON')) {
                try {
                    y.focus();
                } catch (error) {
                    // Dont care
                }
            }
            if (onComplete) {
                onComplete();
            }
        },
        ...vars
    });
};
