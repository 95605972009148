import gsap from 'gsap';
import Viewport from '../core/Viewport';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {
    const dom = $(el);
    const firstList = dom.find('[data-first]');
    const secondtList = dom.find('[data-second]');
    const button = dom.find('[aria-expanded]');
    const buttonTextWrapper = button.find('[data-text]');
    const initialButtonText = buttonTextWrapper.text();
    const items = dom.find('[data-item]');
    const itemCount = items.length;

    let columns = null;

    const distributeItems = cutoff => {
        firstList.empty().append(items.slice(0, cutoff));
        secondtList.empty().append(items.slice(cutoff));
    };

    const update = targetColumns => {
        columns = targetColumns;
        if (columns === 1 && itemCount > 12) {
            distributeItems(8);
        } else if (columns === 2 && itemCount > 18) {
            distributeItems(14);
        } else if (columns === 3 && itemCount > 24) {
            distributeItems(18);
        } else {
            firstList.empty().append(items);
            secondtList.empty().attr('hidden', '');
            button.attr('hidden', '').attr('aria-expanded', false);
            buttonTextWrapper.text(initialButtonText);
        }
    };

    const onBreakpoint = () => {
        const bp = Viewport.breakpoint.name;
        if (bp === 'none' || bp === 's') {
            if (columns !== 1) {
                update(1);
            }
        } else if (bp === 'sp') {
            if (columns !== 2) {
                update(2);
            }
        } else if (columns !== 3) {
            update(3);
        }
    };

    const onToggle = () => {
        const shouldOpen = button.attr('aria-expanded') === 'false';
        button.attr('aria-expanded', shouldOpen);
        buttonTextWrapper.text(shouldOpen ? button.data('less') : initialButtonText);
        const node = secondtList.get(0);
        if (shouldOpen) {
            secondtList.attr('hidden', null);
            gsap.set(node, { height: 'auto' });
            gsap.from(node, {
                height: 0,
                duration: 0.35,
                ease: 'power2.inOut',
                onComplete: () => {
                    gsap.set(node, { height: 'auto' });
                }
            });
        } else {
            gsap.to(node, {
                height: 0,
                duration: 0.35,
                ease: 'power2.inOut',
                clearProps: 'height',
                onComplete: () => {
                    secondtList.attr('hidden', '');
                }
            });
        }
    };

    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);
        button.on('click', onToggle);
        onBreakpoint();
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        button.off('click', onToggle);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
