import gsap from 'gsap';
import { loadFlickity } from '../lib/async-bundles';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default (el, props = {}) => {

    const $el = $(el);
    const html = $('html');
    const slider = $el.find('[data-slider]').get(0) || el;
    const nav = $el.find('[data-nav]').get(0);
    const dragger = $el.find('[data-dragger]').get(0);
    const prevBtn = nav ? nav.querySelector('[data-nav="prev"]') : null;
    const nextBtn = nav ? nav.querySelector('[data-nav="next"]') : null;
    const dots = nav ? nav.querySelector('[data-dots]') : null;

    let xTo;
    let yTo;
    let flkty;
    let dragging = false;

    let Flickity;

    const updateNav = () => {
        if (!nav || !flkty) {
            return;
        }
        const {
            size,
            slideableWidth
        } = flkty;
        if (size.width >= slideableWidth) {
            // Hide the nav completely if the slider isn't... slideable.
            nav.hidden = true;
            return;
        }
        nav.hidden = false;
        // Maybe update dots
        if (dots) {
            const numCells = flkty.slides.length;
            const numDots = dots.querySelectorAll('button').length;
            if (numDots === numCells) {
                return;
            }
            const dotButtonHtml = dots.querySelector('button').outerHTML;
            dots.innerHTML = '';
            if (numCells <= 1) {
                return;
            }
            for (let i = 0; i < numCells; i += 1) {
                const dotButton = $(dotButtonHtml)
                    .get(0);
                dotButton.setAttribute('aria-label', `Click to select slide ${i + 1}`); // TODO translate
                if (i === flkty.selectedIndex) {
                    dotButton.classList.add('is-current');
                } else {
                    dotButton.classList.remove('is-current');
                }
                dots.appendChild(dotButton);
            }
        }
    };

    const destroyFlickity = () => {
        if (!flkty) {
            return;
        }
        dragging = false;
        flkty.destroy();
        flkty = null;
        $(el)
            .off('click');
        $(slider)
            .off('focusin');
    };

    const createFlickity = () => {

        destroyFlickity();

        flkty = new Flickity(slider, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            groupCells: true,
            prevNextButtons: false,
            pageDots: false,
            freeScroll: false,
            freeScrollFriction: 0.045,
            resize: false,
            adaptiveHeight: false,
            setGallerySize: true,
            wrapAround: false,
            accessibility: false,
            on: {
                resize() {
                    updateNav();
                },
                dragStart() {
                    document.ontouchmove = e => e.preventDefault();
                    dragging = true;
                },
                dragEnd() {
                    document.ontouchmove = () => true;
                    dragging = false;
                },
                dragMove(e) {
                    if (dragger && dragging) {
                        const position = eventToPosition(e);
                        xTo(position.x);
                        yTo(position.y);
                    }
                },
                pointerDown() {
                    if (dragger) {
                        dragger.classList.add('is-dragging');
                    }
                },
                pointerUp() {
                    if (dragger) {
                        dragger.classList.remove('is-dragging');
                    }
                },
                select() {
                    this.cells.forEach(({ element }) => {
                        element.removeAttribute('aria-hidden');
                    });
                    if (prevBtn && nextBtn) {
                        if (!this.options.wrapAround && this.selectedIndex <= 0) {
                            prevBtn.setAttribute('aria-disabled', 'true');
                            prevBtn.setAttribute('tabindex', '-1');
                        } else {
                            prevBtn.removeAttribute('aria-disabled');
                            prevBtn.removeAttribute('tabindex');
                        }
                        if (!this.options.wrapAround && this.selectedIndex >= this.slides.length - 1) {
                            nextBtn.setAttribute('aria-disabled', 'true');
                            nextBtn.setAttribute('tabindex', '-1');
                        } else {
                            nextBtn.removeAttribute('aria-disabled');
                            nextBtn.removeAttribute('tabindex');
                        }
                    }
                    if (dots) {
                        $(dots)
                            .find('button.is-current')
                            .removeClass('is-current');
                        $(dots)
                            .find('button')
                            .get(this.selectedIndex)
                            .classList
                            .add('is-current');
                    }
                },
                ready() {
                    this.resize();
                }
            },
            ...props
        });

        $(slider)
            .on('focusin', 'a', e => {
                slider.parentNode.scrollLeft = 0;
                const { triggerTarget: link } = e;
                const cell = flkty.cells.find(({ element }) => element.contains(link));
                if (!cell) {
                    return;
                }
                const index = flkty.cells.indexOf(cell);
                if (index > -1) {
                    flkty.selectCell(index);
                }
            });

        $(el)
            .on('click', '[data-nav="prev"]', () => {
                flkty.previous();
            });

        $(el)
            .on('click', '[data-nav="next"]', () => {
                flkty.next();
            });

        $(el)
            .on('click', '[data-dots] button', e => {
                const { triggerTarget: target } = e;
                const index = Array.from(dots.children).indexOf(target);
                flkty.select(index);
            });

        updateNav();

        console.log('flickity created');

    };

    const eventToPosition = e => {
        const rect = slider.getBoundingClientRect();
        return {
            x: e.clientX - rect.x,
            y: e.clientY - rect.y
        };
    };

    const onMouseMove = e => {
        const position = eventToPosition(e);
        xTo(position.x);
        yTo(position.y);
    };

    const onMouseLeave = () => {
        if (html.hasClass('using-mouse')) {
            dragging = false;
            document.removeEventListener('mousemove', onMouseMove);
            gsap.killTweensOf(dragger);
            gsap.set(dragger, { display: 'none' });
            // gsap.to(dragger, { duration: 0.6, left: '50%', top: '50%', ease: 'power3' });
        }
    };

    const onMouseEnter = e => {
        if (html.hasClass('using-mouse')) {
            const position = eventToPosition(e);
            gsap.killTweensOf(dragger);
            gsap.set(dragger, { left: position.x, top: position.y, display: 'block' });
            xTo = gsap.quickTo(dragger, 'left', { duration: 0.4, ease: 'power3' });
            yTo = gsap.quickTo(dragger, 'top', { duration: 0.4, ease: 'power3' });
            document.addEventListener('mousemove', onMouseMove);
        }
    };

    const onResize = () => {
        if (!flkty) {
            return;
        }
        setTimeout(() => {
            flkty.resize();
        }, 0);
    };

    const destroy = () => {
        destroyFlickity();
        if (dragger) {
            $(slider).off('mouseenter', onMouseEnter);
            $(slider).off('mouseleave', onMouseLeave);
        }
        Viewport.off('resize', onResize);
        document.removeEventListener('mousemove', onMouseMove);
    };

    const init = () => {
        loadFlickity(module => {
            Flickity = module.default;
            createFlickity();
        });
        if (dragger) {
            $(slider).on('mouseenter', onMouseEnter);
            $(slider).on('mouseleave', onMouseLeave);
            $el.find('.flickity-viewport').css('cursor', 'none');
        }
        Viewport.on('resize', onResize);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
