import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const dom = $(el);
    const increase = dom.find('[data-increase]');
    const decrease = dom.find('[data-decrease]');
    const input = dom.find('input[type="number"]');
    const minValue = parseInt(input.attr('min'), 10);
    const maxValue = parseInt(input.attr('max'), 10);

    const announcer = dom.find('[data-announcer]');

    const getValue = () => parseInt(input.val(), 10);

    const onUpdateValue = () => {
        const value = getValue();
        decrease.attr('aria-disabled', value === minValue);
        increase.attr('aria-disabled', value === maxValue);
        if (announcer) {
            const announcerText = announcer.data('template').replace('{count}', value);
            console.log({ announcerText });
            announcer.text(announcerText);
        }
    };

    const updateValue = value => {
        const updatedValue = getValue() + value;
        if (updatedValue >= minValue && updatedValue <= maxValue) {
            input.val(updatedValue);
            onUpdateValue();
        }
    };

    const init = () => {
        input.on('change', onUpdateValue);
        increase.on('click', () => {
            updateValue(1);
        });
        decrease.on('click', () => {
            updateValue(-1);
        });
    };

    const destroy = () => {
        input.off('change', onUpdateValue);
        increase.off('click');
        decrease.off('click');
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
