import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default button => {

    const contentId = button.getAttribute('aria-controls');
    const content = document.getElementById(contentId);

    if (!content) {
        console.warn(`#${contentId} not found`);
        return null;
    }

    let isExpanded = button.getAttribute('aria-expanded') === 'true';

    const collapse = () => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        content.hidden = true;
        button.setAttribute('aria-expanded', 'false');
    };

    const expand = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        content.hidden = false;
        button.setAttribute('aria-expanded', 'true');
    };

    const toggle = () => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    const onClick = () => {
        toggle();
    };

    const init = () => {
        button.addEventListener('click', onClick);
        $(button).data('_accordion', { expand, collapse, toggle });
    };

    const destroy = () => {
        button.removeEventListener('click', onClick);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
